/* eslint-disable no-use-before-define */

import { is } from './is'

/**
 * Compares two objects to check if they are equal, by default it will
 * also check if both of them contain the exact same keys.
 *
 * @param {Object} obj1 - First object
 * @param {Object} obj2 - Second object
 * @param {Object} options - Function options, for now you can disable
 * key parity checking
 * @returns {Boolean} - Whether the two objects are equal or not.
 */
export function isEqualObject (obj1, obj2, options = { ignoreKeys: false }) {
  if (!is(obj1, 'Object') || !is(obj2, 'Object')) return false

  const sameKeys = options.ignoreKeys
    || (Object.keys(obj1).length === Object.keys(obj2).length
    && Object.keys(obj1).every(key => Object.keys(obj2).includes(key)))

  const sameValues = Object.entries(obj1).every(([ key, value ]) => {
    if (is(value, 'Array')) return isEqualArray(value, obj2[key])
    if (is(value, 'Object')) return isEqualObject(value, obj2[key])
    return value === obj2[key]
  })

  return sameKeys && sameValues
}

/**
 * Performs a deep comparison between two arrays to check whether
 * they're actually equal.
 *
 * @param {Array<any>} arrayOne - First array
 * @param {Array<any>} arrayTwo - Second array (duh)
 * @returns {Boolean} - Whether the two arrays are equal or not.
 */
export function isEqualArray (arrayOne, arrayTwo) {
  if (!Array.isArray(arrayOne) || !Array.isArray(arrayTwo)) return false
  if ((arrayOne || []).length !== (arrayTwo || []).length) return false

  // Checks not only the values of each item but also the order
  // of the arrays.
  return arrayOne.every((item, idx) => {
    if (is(item, 'Array')) return isEqualArray(item, arrayTwo[idx])
    if (is(item, 'Object')) return isEqualObject(item, arrayTwo[idx])
    return item === arrayTwo[idx]
  })
}
