<template>
  <div :class="classes">
    <slot name="placeholder">
      <div v-if="!isSmall" class="top">
        <c-character
          class="character"
          :type="character"
          :size="isMobile ? 130 : 150"
        />

        <div class="top-wrapper">
          <p class="text">Arraste seu arquivo e solte aqui</p>

          <span class="separator">Ou</span>
        </div>
      </div>
    </slot>

    <div class="bottom">
      <c-button
        :class="{ btn: !smallAddSize }"
        type="button"
        :grey="grey && !primaryButton"
        :primary="primaryButton"
        :icon="smallAddSize ? 'plus' : null"
        data-testId="file-search"
        @click="$emit('add')"
      >
        <span v-if="!smallAddSize">{{ addText }}</span>
      </c-button>

      <slot name="info" />
    </div>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'EmptyList',

  mixins: [ MediaQuery ],

  props: {
    extensions: {
      type: Array,
      required: true
    },

    character: {
      type: String,
      default: 'photo'
    },

    multiple: Boolean,

    isSmall: Boolean,

    grey: Boolean,

    addSize: {
      type: String,
      default: '',
    },

    addText: {
      type: String,
      default: '',
    },

    primaryButton: Boolean,
  },

  computed: {
    smallAddSize () { return this.addSize === 'small' },
    classes () {
      return [ 'c-upload-empty-list', {
        '-grey': this.grey
      } ]
    }
  }
}
</script>

<style lang="scss">
.c-upload-empty-list {
  @extend %flexbox;

  &.-grey {
    background-color: color-var(text, base-02);

    & > .bottom > .c-button:not(.-primary) {
      background: color-var(text, base-10);
    }
  }

  & > .top {
    @extend %flexbox;
    margin-bottom: 20px;
    width: 100%;

    & > .top-wrapper {
      @include responsive (xs-mobile, mobile) { display: none; }

      & > .text {
        @include typo(h2);
        margin: { top: 30px; bottom: 20px; }
      }

      & > .separator {
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: var(--base-font-family);
        font-size: 11px;
        letter-spacing: -0.11px;
        color: color-var(text, base-50);

        text-transform: uppercase;

        &:before, &:after {
          content: '';
          display: inline-block;
          width: 40px;
          height: 1px;

          background-color: color-var(text, base);
          opacity: .1;
        }

        &:before { margin-right: 15px; }
        &:after { margin-left: 15px; }
      }
    }
  }

  & > .bottom {
    @extend %flexbox;

    width: 100%;

    & > .btn { width: 100%; max-width: 260px; }

    & > .text {
      color: color-var(text, base-30);
      font-family: var(--base-font-family);
      text-align: center;
      letter-spacing: -0.14px;
      font-size: 14px;
      max-width: 265px;

      margin-top: 20px;
    }

    & > .input { display: none; }
  }
}

%flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
