import { computed, ref } from 'vue'
import { useGetters, useMutations } from 'vuex-composition-helpers/dist'

/**
 * @description
 * A helper function to create a writable computed reference to the store state.
 * Is preferred that the mutationKey is the same as the getterKey to reference the same state.
 *
 * @param {string} getterKey - The key of the getter to be used.
 * @param {string} mutationKey - The key of the mutation to be used.
 * @returns {ComputedRef} - A writable computed reference to the store state.
 */
export const storeStateToRef = (getterKey, mutationKey) => {
  const verifiedMutationKey = mutationKey || getterKey

  const { [getterKey]: state } = useGetters([ getterKey ])
  const { [verifiedMutationKey]: set } = useMutations([ verifiedMutationKey ])

  if (!state) console.warn(`storeStateToRef: Getter ${getterKey} not found`)
  if (!set) console.warn(`storeStateToRef: Mutation ${verifiedMutationKey} not found`)

  if (!state || !set) {
    console.warn('storeStateToRef: returning ref(null) not attached to the store')
    return ref(null)
  }

  return computed({ get: () => state.value, set })
}
