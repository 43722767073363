const isMobile = window.innerWidth < 720

export default {
  cpf: {
    title: 'CPF',
    status: 'default',
    data: {},
    files: [],
    filesPlaceholder: [],
    filesLimit: 10,
    filesSlots: true,
    filesSizeLimit: 8,
    customFields: {},
    requiredFiles: false,
    requiredFields: [],
  },

  ctps: {
    title: 'Carteira de trabalho',
    status: 'default',
    text: 'Para esta categoria, devem ser anexados 2 arquivos, referentes a <b>primeira</b> e <b>segunda</b> folhas da carteira de trabalho.',
    data: {},
    files: [],
    filesPlaceholder: [
      !isMobile ? 'Arraste a primeira folha do documento aqui' : 'Toque e insira a primeira folha do documento',
      !isMobile ? 'Arraste a segunda folha do documento aqui' : 'Toque e insira a segunda folha do documento'
    ],
    filesLimit: 10,
    filesSlots: true,
    customFields: {},
    requiredFiles: false,
    requiredFields: [],
    filesSizeLimit: 8
  },

  rg: {
    title: 'Registro geral (RG)',
    status: 'default',
    text: 'Para esta categoria, devem ser anexados 2 arquivos, referentes a <b>frente</b> e ao <b>verso</b> do documento.',
    data: {},
    files: [],
    filesPlaceholder: [
      !isMobile ? 'Arraste a frente do documento aqui' : 'Toque e insira a frente do documento',
      !isMobile ? 'Arraste o verso do documento aqui' : 'Toque e insira o verso do documento'
    ],
    filesLimit: 10,
    filesSlots: true,
    customFields: {},
    requiredFiles: false,
    requiredFields: [],
    filesSizeLimit: 8
  },

  reservist: {
    title: 'Reservista',
    status: 'default',
    data: {},
    files: [],
    filesPlaceholder: [],
    filesLimit: 10,
    filesSlots: true,
    customFields: {},
    requiredFiles: false,
    requiredFields: [],
    filesSizeLimit: 8
  },

  driver_license: {
    title: 'Carteira de motorista (CNH)',
    status: 'default',
    text: 'Para esta categoria, devem ser anexados 2 arquivos, referentes a <b>frente</b> e ao <b>verso</b> do documento.',
    data: {},
    files: [],
    filesPlaceholder: [
      !isMobile ? 'Arraste a frente do documento aqui' : 'Toque e insira a frente do documento',
      !isMobile ? 'Arraste o verso do documento aqui' : 'Toque e insira o verso do documento'
    ],
    filesLimit: 10,
    filesSlots: true,
    customFields: {},
    requiredFiles: false,
    requiredFields: [],
    filesSizeLimit: 8
  },

  electoral_card: {
    title: 'Título de Eleitor',
    status: 'default',
    data: {},
    files: [],
    filesPlaceholder: [],
    filesLimit: 10,
    filesSlots: true,
    customFields: {},
    requiredFiles: false,
    requiredFields: [],
    filesSizeLimit: 8
  },
}
