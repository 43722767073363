export const INTERNSHIP_RELATIONSHIP_ID = 4
export const SELF_EMPLOYED_RELATIONSHIP_ID = 6
export const CNPJ_RELATIONSHIP_ID = 11
export const COOPERATIVE_MEMBER_RELATIONSHIP_ID = 15
export const ASSOCIATE_RELATIONSHIP_ID = 16

export const relationships = {
  'trabalhador autônomo': SELF_EMPLOYED_RELATIONSHIP_ID,
  'pessoa jurídica': CNPJ_RELATIONSHIP_ID,
  'cooperado': COOPERATIVE_MEMBER_RELATIONSHIP_ID,
  'associado': ASSOCIATE_RELATIONSHIP_ID
}
